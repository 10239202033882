import './App.css';
import Markdown from 'react-markdown';

// Define your Markdown content
const markdownContent = `
# Japanese Learning Resources

## Hiragana/Katakana
Hiragana: [Tofugu Learn Hiragana](https://www.tofugu.com/japanese/learn-katakana/)  
Katakana: [Tofugu Learn Katakana](https://www.tofugu.com/japanese/learn-hiragana/)  
Katakana: [Wanikani](http://wanikani.com/)

## Lessons
Genki 1 Walkthrough ([ToKini Andy](https://www.youtube.com/playlist?list=PLA_RcUI8km1NMhiEebcbqdlcHv_2ngbO2))  
Genki 1 Walkthrough ([Game Gengo](https://www.youtube.com/@GameGengo/playlists?view=50&sort=dd&shelf_id=4))  
Genki Alternative - [Tae Kim's Guide to Learning Japanese](https://guidetojapanese.org/learn/category/grammar-guide/grammar-start/)  

## Anki Decks
[Genki 1](https://ankiweb.net/shared/info/1742947823)  
[Genki 2](https://ankiweb.net/shared/info/969261095)  
[Genki Kanji](https://ankiweb.net/shared/info/95480491)

## Dictionaries
[JapanDict](https://www.japandict.com/)  
[Jisho](https://jisho.org/)
`;

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>
          TylerJDMoore
        </h1>
        <h2>This page intentionally left blank</h2>
        <h2>This website is WIP and not reflective of anything. Resources below may or may not be useful, and are not endorsed or reflective of myself or my opinions in any way. Site may one day be made to look presentable, but who knows, it is not a priority.</h2>
      </header>

      <body className="App-body">
        <Markdown>{markdownContent}</Markdown>
      </body>
    </div>
  );
}

export default App;
